import React, { Component } from 'react';

import './App.css';

import { ToastProvider } from './util/Toast';
import Router from './Router';

class App extends Component {
  render() {
    return (
      <ToastProvider>
        <Router />
        <footer style={{ margin: '2rem', marginTop: '4rem' }}>
          <small className="my-3">
            <a href="http://www.beian.miit.gov.cn">
              备案号：京ICP备19035335号-2
            </a>
          </small>
        </footer>
      </ToastProvider>
    );
  }
}

export default App;
