import React, { PureComponent } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface TeamRowHeaderProps {
  withRank: boolean;
  className?: string;
}

class TeamRowHeader extends PureComponent<TeamRowHeaderProps> {
  render() {
    const { withRank, className } = this.props;

    return (
      <Row noGutters className={className}>
        <Col xs={4}>
          <Row noGutters>
            <Col xs={8}>{withRank && <h5>Rank</h5>}</Col>
            <Col xs={4}>
              <h5>ID</h5>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <h5>Team</h5>
        </Col>
        <Col xs={2}>
          <h5>Score</h5>
        </Col>
      </Row>
    );
  }
}

export default TeamRowHeader;
