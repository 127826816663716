import React from 'react';

export default () => (
  <>
    <p>Instructions:</p>
    <ol>
      <li>Input the ID for the team whose scores you are entering.</li>
      <li>
        Pick the problem set corresponding to the submitted answers. You must
        have scores submitted for all of the sets prior. If a team skips a set,
        you should verify with that team that this is intentional and input all
        zeroes for that set.
      </li>
      <li>
        For sets 1 through 8, simply mark the answer as correct or incorrect.
        The number of points awarded for that problem is filled automatically.
        See below for keyboard shortcuts that will help speed this up.
      </li>
      <li>
        Set 9 is special because it is possible to earn partial credit.
        Instructions for that set are located in the submission form.
      </li>
    </ol>

    <p>Keyboard shortcuts:</p>
    <ul>
      <li>
        Correct: <code>1</code> or <code>d</code> or <code>RightArrow</code>
      </li>
      <li>
        Incorrect: <code>0</code> or <code>a</code> or <code>LeftArrow</code>
      </li>
      <li>
        The cursor will automatically advance. Hit <code>Shift+Tab</code> to
        move back.
      </li>
    </ul>
  </>
);
