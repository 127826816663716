import React, { PureComponent } from 'react';

import Container from 'react-bootstrap/Container';

import ScoreEntry from '../components/ScoreEntry';

export default class AdminPage extends PureComponent {
  render() {
    return (
      <Container className="mt-4">
        <ScoreEntry />
      </Container>
    );
  }
}
