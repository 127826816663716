import * as React from 'react';
import { report, setToast } from '../util/Toast';
import { fetchWithRedirect } from '../util/Api';

const XlsxUpload: React.FunctionComponent<{}> = () => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [file, setFile] = React.useState<any>(null);
  const [failedRows, setFailedRows] = React.useState<Array<number>>([]);

  const onFileChange = (event: React.FormEvent<HTMLInputElement>) => {
    const ev = event.target as any;
    if (ev.files && ev.files.length !== 0) {
      const file = ev.files[0];
      if (file.name.endsWith('.xlsx')) {
        setFile(ev.files[0]);
      } else {
        setToast({
          contents: 'File must be .xlsx!',
          mode: 'error',
        });
      }
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('xlsx', file, file.name);

    fetchWithRedirect('/api/admin/submit_xlsx', {
      method: 'POST',
      credentials: 'include',
      body: formData,
    })
      .then(
        report(
          `Successfully processed ${file.name}`,
          `Failed to process ${file.name}`
        )
      )
      .then((res) => res.json())
      .then((failedRows) => {
        formRef.current && formRef.current.reset();
        setFailedRows(failedRows);
      });
  };

  return (
    <>
      <h5>Upload XLSX file</h5>
      <form onSubmit={onSubmit} ref={formRef}>
        <input type="file" name="xlsx" onChange={onFileChange} required />
        <input type="submit" value="Upload!" />
      </form>
      {failedRows.length !== 0 && (
        <>
          <h5 style={{ color: 'red' }}>
            Failed to process rows: {JSON.stringify(failedRows)}
          </h5>
        </>
      )}
    </>
  );
};

export default XlsxUpload;
