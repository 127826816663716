const IsLoggedIn = () => {
  return document.cookie.indexOf('logged_in=yes') !== -1;
};

const IsAdmin = () => {
  return document.cookie.indexOf('role=admin') !== -1 || IsSuperuser();
};

const IsSuperuser = () => {
  return document.cookie.indexOf('role=superuser') !== -1;
};

export { IsLoggedIn, IsAdmin, IsSuperuser };
