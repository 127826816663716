import React, { Component } from 'react';

import ITeam from '../../common/models/team';

import { MAX_TEAMS_PER_COLUMN } from '../constants';
import { appendOrdinalSuffix } from '../util/Ordinal';
import TeamRow from './TeamRow';
import TeamRowHeader from './TeamRowHeader';

interface ILeaderboardProps {
  teams: ITeam[];
}

class Leaderboard extends Component<ILeaderboardProps> {
  renderRow = (team: ITeam) => {
    return <TeamRow key={team.teamId} team={team} showRank />;
  };

  render() {
    const { teams } = this.props;

    let teamSlice = teams.slice(0, MAX_TEAMS_PER_COLUMN);
    let moreTeamsTiedForRank: number | null = null;
    if (
      teams.length > MAX_TEAMS_PER_COLUMN &&
      teams[MAX_TEAMS_PER_COLUMN - 1].totalScore ===
        teams[MAX_TEAMS_PER_COLUMN].totalScore
    ) {
      moreTeamsTiedForRank = teams[MAX_TEAMS_PER_COLUMN].rank;
      teamSlice = teamSlice.slice(0, MAX_TEAMS_PER_COLUMN - 1);
    }

    return (
      <>
        <h4 className="text-center mb-4">Leaderboard</h4>
        <TeamRowHeader withRank />
        {teamSlice.map(this.renderRow)}
        {moreTeamsTiedForRank && (
          <h6 className="mt-2 text-center">
            ... with more teams tied for{' '}
            {appendOrdinalSuffix(moreTeamsTiedForRank)}
          </h6>
        )}
      </>
    );
  }
}

export default Leaderboard;
