import React, { PureComponent } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ITeam from '../../common/models/team';

import { MAX_TEAMS_PER_COLUMN, PAGE_FLIP_INTERVAL_MS } from '../constants';
import TeamRow from './TeamRow';
import TeamRowHeader from './TeamRowHeader';

interface IScoreboardProps {
  teams: ITeam[];
}

interface IScoreboardState {
  numPages: number;
  pageNum: number;
}

class Scoreboard extends PureComponent<IScoreboardProps, IScoreboardState> {
  interval?: NodeJS.Timeout;

  constructor(props: IScoreboardProps) {
    super(props);

    const numPages = Math.ceil(props.teams.length / (2 * MAX_TEAMS_PER_COLUMN));

    this.state = {
      numPages,
      pageNum: 1,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(state => ({
        pageNum: 1 + (state.pageNum % state.numPages),
      }));
    }, PAGE_FLIP_INTERVAL_MS);
  }

  componentWillUnmount() {
    this.interval && clearInterval(this.interval);
  }

  componentDidUpdate(prevProps: IScoreboardProps, prevState: IScoreboardState) {
    if (prevProps.teams.length !== this.props.teams.length) {
      const numPages = Math.ceil(
        this.props.teams.length / (2 * MAX_TEAMS_PER_COLUMN)
      );

      this.setState(state => ({
        numPages,
        pageNum: Math.min(numPages, state.pageNum),
      }));
    }
  }

  renderRow = (team: ITeam) => {
    return <TeamRow key={team.teamId} team={team} showRank={false} />;
  };

  render() {
    const { teams } = this.props;
    const { pageNum, numPages } = this.state;

    const offset = 2 * (pageNum - 1) * MAX_TEAMS_PER_COLUMN;
    const firstSlice = teams.slice(offset, offset + MAX_TEAMS_PER_COLUMN);
    const secondSlice = teams.slice(
      offset + MAX_TEAMS_PER_COLUMN,
      offset + 2 * MAX_TEAMS_PER_COLUMN
    );

    return (
      <>
        <h4 className="text-center mb-4">
          Scoreboard (page {pageNum} of {numPages})
        </h4>
        <Row noGutters>
          <Col lg={6}>
            <TeamRowHeader withRank={false} />
            {firstSlice.map(this.renderRow)}
          </Col>
          <Col lg={6}>
            <TeamRowHeader withRank={false} className="d-none d-lg-flex" />
            {secondSlice.map(this.renderRow)}
          </Col>
        </Row>
      </>
    );
  }
}

export default Scoreboard;
