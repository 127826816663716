import React from 'react';
import { Container, Tabs, Tab, Form, Button, Row, Col } from 'react-bootstrap';

import Timer from '../components/Timer';
import { report } from '../util/Toast';
import { fetchWithRedirect } from '../util/Api';

export default class SuperuserPage extends React.Component {
  render() {
    return (
      <Container className="mt-4">
        <Tabs id="superuser-tabs" mountOnEnter unmountOnExit>
          <Tab eventKey="display" title="Display Settings">
            <DisplaySettings />
          </Tab>
          <Tab eventKey="time" title="Time Controls">
            <TimeControls />
          </Tab>
          <Tab eventKey="users" title="Authorize Users">
            <AuthorizeUsers />
          </Tab>
          <Tab eventKey="import" title="Import Teams">
            <ImportTeams />
          </Tab>
          <Tab eventKey="export" title="Export Scores">
            <ExportScores />
          </Tab>
          <Tab eventKey="reset" title="Clear All Data">
            <Row className="mt-3">
              <Col xs={6}>
                <Button
                  block
                  onClick={() =>
                    fetchWithRedirect('/api/superuser/clear_data', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      credentials: 'include',
                    }).then(
                      report(
                        `Successfully cleared data`,
                        `Could not clear data`
                      )
                    )
                  }
                >
                  Clear all data
                </Button>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Container>
    );
  }
}

class DisplaySettings extends React.Component {
  handleHideClick = () => {
    localStorage.setItem('hideButtons', 'true');
  };
  handleShowClick = () => {
    localStorage.setItem('hideButtons', 'false');
  };

  render() {
    return (
      <Row className="mt-3">
        <Col md={6}>
          <Button block onClick={this.handleShowClick}>
            Show buttons
          </Button>
          <Button block onClick={this.handleHideClick}>
            Hide buttons
          </Button>
        </Col>
      </Row>
    );
  }
}

class TimeControls extends React.Component<{}, { duration: string }> {
  state = {
    duration: '',
  };

  handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const payload = this.state.duration
      ? { durationOverride: Number.parseInt(this.state.duration) * 1000 }
      : {};
    fetchWithRedirect('/api/superuser/start_timer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      credentials: 'include',
    }).then(report(`Successfully started timer`, `Could not start timer`));
  };

  handleChangeDuration = (event: any) => {
    this.setState({
      duration: String(event.target.value).replace('/[^d]/', ''),
    });
  };

  render() {
    return (
      <>
        <Row className="mt-3">
          <Col md={6}>
            <Timer />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form onSubmit={this.handleFormSubmit}>
              <Form.Group>
                <Form.Control
                  name="duration"
                  placeholder="Duration override (seconds)"
                  value={this.state.duration}
                  onChange={this.handleChangeDuration}
                />
              </Form.Group>
              <Button id="submit" type="submit" block>
                Start Timer
              </Button>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

class AuthorizeUsers extends React.Component<
  {},
  { username: string; role: string }
> {
  state = {
    username: '',
    role: 'none',
  };

  handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const payload = this.state;
    fetchWithRedirect('/api/superuser/authorize', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      credentials: 'include',
    }).then(report(`Operation was successful`, `Could not perform operation`));
  };

  handleChangeUsername = (event: any) => {
    this.setState({
      username: String(event.target.value).trim(),
    });
  };

  handleChangeRole = (event: any) => {
    this.setState({
      role: String(event.target.value).trim(),
    });
  };

  render() {
    return (
      <>
        <Row className="mt-3">
          <Col md={6}>
            <Form onSubmit={this.handleFormSubmit}>
              <Form.Group>
                <Form.Control
                  name="username"
                  placeholder="Username to modify"
                  value={this.state.username}
                  onChange={this.handleChangeUsername}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  as="select"
                  value={this.state.role}
                  onChange={this.handleChangeRole}
                >
                  <option value="none">none</option>
                  <option value="admin">admin</option>
                  <option value="superuser">superuser</option>
                </Form.Control>
              </Form.Group>
              <Button id="submit" type="submit" block>
                Modify Permissions
              </Button>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

class ImportTeams extends React.Component<{}, { csv: string }> {
  state = {
    csv: '',
  };

  handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const payload = this.state;
    fetchWithRedirect('/api/superuser/import_teams', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      credentials: 'include',
    }).then(report(`Operation was successful`, `Could not perform operation`));
  };

  handleChangeCsv = (event: any) => {
    this.setState({
      csv: String(event.target.value),
    });
  };

  render() {
    return (
      <>
        <Row className="mt-3">
          <Col md={6}>
            <Form onSubmit={this.handleFormSubmit}>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  name="csv"
                  placeholder="CSV of teams with ID and Name columns (no header row)"
                  value={this.state.csv}
                  onChange={this.handleChangeCsv}
                  rows={20}
                />
              </Form.Group>
              <Button id="submit" type="submit" block>
                Import Teams
              </Button>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

class ExportScores extends React.Component {
  handleDownload = () => {
    fetchWithRedirect('/api/superuser/export_scores', {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'include',
    });
  };

  render() {
    return (
      <Row className="mt-3">
        <Col md={6}>
          <Button block href="/api/superuser/export_scores">
            Export as CSV
          </Button>
        </Col>
      </Row>
    );
  }
}
