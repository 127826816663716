import React from 'react';

interface ITimerProps {}

interface ITimerState {
  secondsLeft: number;
  started: boolean;
}

const getCurrentUnixTime = () => Date.now();

export default class Timer extends React.Component<ITimerProps, ITimerState> {
  updateInterval?: any;
  syncInterval?: any;

  localStartTime: number = 0;
  localEndTime: number = 0;
  serverStartTime: number = 0;
  duration: number = 0;

  constructor(props: ITimerProps) {
    super(props);

    this.state = {
      started: false,
      secondsLeft: 0,
    };
  }

  componentDidMount() {
    this.updateInterval = setInterval(this.update, 100);
    this.syncInterval = setInterval(this.syncWithServer, 10000);
    this.syncWithServer();
  }

  componentWillUnmount() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
    if (this.syncInterval) {
      clearInterval(this.syncInterval);
    }
  }

  syncWithServer = () => {
    fetch('/api/time', {
      method: 'GET',
      cache: 'no-cache',
    })
      .then((data) => data.json())
      .then(
        ({
          currentServerTime,
          gutsInfo,
        }: {
          currentServerTime: number;
          gutsInfo: any;
        }) => {
          const serverStartTime = gutsInfo.startTime;
          if (
            this.serverStartTime !== serverStartTime ||
            this.duration !== gutsInfo.duration
          ) {
            this.serverStartTime = serverStartTime;
            this.duration = gutsInfo.duration;
            const elapsed = currentServerTime - serverStartTime;
            this.localStartTime = getCurrentUnixTime() - elapsed;
            this.localEndTime = this.localStartTime + gutsInfo.duration;
            this.setState({
              started: serverStartTime !== 0,
            });
          }
        }
      );
  };

  update = () => {
    const secondsLeft = Math.floor(
      Math.max(
        0,
        this.state.started ? this.localEndTime - getCurrentUnixTime() : 0
      ) / 1000
    );
    if (secondsLeft !== this.state.secondsLeft) {
      this.setState({
        secondsLeft,
      });
    }
  };

  render() {
    const seconds = String(this.state.secondsLeft % 60).padStart(2, '0');
    var minutes: any = Math.floor(this.state.secondsLeft / 60);
    var hours: any = 0;
    if (minutes >= 60) {
      minutes -= 60;
      hours += 1;
    }
    minutes = String(minutes).padStart(2, '0');
    hours = String(hours).padStart(1, '0');
    return (
      <p className="time-display text-center">{`${hours}:${minutes}:${seconds}`}</p>
    );
  }
}
