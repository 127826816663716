export const MAX_TEAMS_PER_COLUMN = 12;
export const PAGE_FLIP_INTERVAL_MS = 8000;
export const DATA_FETCH_INTERVAL_MS = PAGE_FLIP_INTERVAL_MS;

// TODO: implement
export const SHOW_SET_9_SCORES = true;

// TODO: this is duplicated in the server
export const PROBLEM_VALUES: { [key: number]: number } = {
  1: 10,
  2: 11,
  3: 12,
  4: 13,
  5: 14,
  6: 16,
  7: 18,
  8: 21,
  9: 25,
};
