import React, { PureComponent } from 'react';

interface IProgressDotsProps {
  number: number;
}

class ProgressDots extends PureComponent<IProgressDotsProps> {
  renderDot = (i: number) => {
    const className = i < this.props.number ? 'colored' : 'uncolored';
    return <div key={i} className={className} />;
  };

  renderDots = () => {
    return Array.from(Array(9).keys()).map(this.renderDot);
  };

  render() {
    return <div className="progress-dots">{this.renderDots()}</div>;
  }
}

export default ProgressDots;
