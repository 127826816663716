import React, { PureComponent } from 'react';

import HomePage from './pages/HomePage';
import { LoginPage, RegisterPage } from './pages/UserPage';
import AdminPage from './pages/AdminPage';
import SuperuserPage from './pages/SuperuserPage';

const routes: { [key: string]: any } = {
  '/': HomePage,
  '/login': LoginPage,
  '/register': RegisterPage,
  '/admin': AdminPage,
  '/superuser': SuperuserPage,
};

export default class Router extends PureComponent {
  render() {
    const Component = routes[window.location.pathname];
    if (Component) {
      return <Component />;
    } else {
      return <p>Not Found</p>;
    }
  }
}
