import React from 'react';

const ToastProvider = ({ children }) => (
  <>
    <Toast />
    {children}
  </>
);

interface IToastState {
  contents: string;
  mode: 'success' | 'error';
}

class Toast extends React.Component<{}, IToastState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      contents: '',
      mode: 'success',
    };

    setToast = this.setToast;
  }

  resetToast = () => {
    this.setState({
      contents: '',
      mode: 'success',
    });
  };

  setToast = (toast: IToastState) => {
    this.setState(toast, () => setTimeout(this.resetToast, 2500));
  };

  render() {
    return (
      <div
        style={{
          position: 'absolute',
          zIndex: 10,
          top: '20px',
          right: '20px',
          width: '250px',
          height: '60px',
          padding: '8px',
          borderRadius: '4px',
          backgroundColor:
            this.state.mode === 'success' ? 'limegreen' : 'orange',
          display: this.state.contents ? 'initial' : 'none',
        }}
      >
        <p>{this.state.contents}</p>
      </div>
    );
  }
}

var setToast: (toast: IToastState) => void = () => {};

const report = (successMsg: string, errMsg: string) => {
  const cb = (res: Response) => {
    if (res.status === 200) {
      setToast({ contents: successMsg, mode: 'success' });
    } else {
      setToast({ contents: errMsg, mode: 'error' });
    }

    return res;
  };

  return cb;
};

export { report, setToast, ToastProvider };
