import React, { PureComponent } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ITeam from '../../common/models/team';

import ProgressDots from './ProgressDots';

interface TeamRowProps {
  team: ITeam;
  showRank: boolean;
}

class TeamRow extends PureComponent<TeamRowProps> {
  render() {
    const { team, showRank } = this.props;
    const { teamId, name, totalScore, setsCompleted, rank } = team;

    return (
      <Row noGutters className="mt-1">
        <Col xs={4}>
          <Row noGutters>
            <Col xs={4}>{showRank && <h5>{rank}</h5>}</Col>
            <Col xs={4}>
              <ProgressDots number={setsCompleted} />
            </Col>
            <Col xs={4}>
              <h5>{teamId}</h5>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <h5 className="font-weight-light text-truncate">{name}</h5>
        </Col>
        <Col xs={2}>
          <h5>{totalScore}</h5>
        </Col>
      </Row>
    );
  }
}

export default TeamRow;
