import React, { PureComponent } from 'react';
import '../Login.css';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { IsLoggedIn } from '../util/Auth';

interface IUserPageProps {
  buttonText: string;
  endpoint: string;
}

class UserPage extends PureComponent<IUserPageProps> {
  componentDidMount() {
    if (IsLoggedIn()) {
      window.location.href = '/';
    }
  }

  render() {
    return (
      <Container className="mt-5 login-container">
        <Form action={`/${this.props.endpoint}`} method="post">
          <Form.Group controlId="username">
            <Form.Control name="username" placeholder="Username" />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Control
              name="password"
              type="password"
              placeholder="Password"
            />
          </Form.Group>
          <Button variant="primary" type="submit" block>
            {this.props.buttonText}
          </Button>
        </Form>
      </Container>
    );
  }
}

const wrapUserPage = (buttonText: string, endpoint: string) => {
  return () => <UserPage buttonText={buttonText} endpoint={endpoint} />;
};

const LoginPage = wrapUserPage('Log In', 'login');
const RegisterPage = wrapUserPage('Register', 'register');

export { LoginPage, RegisterPage };
